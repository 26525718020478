import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/sso/:sysToken?/:id?',
    name: 'Sso',
    component: function () {
      return import('../views/Sso.vue')
    }
  },
  {
    path: '/',
    name: 'Login',
    component: function () {
      return import('../views/Login.vue')
    }
  },
  {
    path: '/project/:id?',
    name: 'Project',
    component: function () {
      return import('../views/Project.vue')
    }
  },
  {
    path: '/person',
    name: 'Person',
    component: function () {
      return import('../views/Person.vue')
    }
  },
  {
    path: '/about',
    name: 'About',
    component: function () {
      return import('../views/About.vue')
    }
  },
  {
    path: '/video',
    name: 'Video',
    component: function () {
      return import('../views/Video.vue')
    }
  },
  {
    path: '/ai',
    name: 'Ai',
    component: function () {
      return import('../views/Ai.vue')
    }
  },
  {
    path: '/crane',
    name: 'Crane',
    component: function () {
      return import('../views/Crane.vue')
    }
  },
  {
    path: '/craneItem',
    name: 'CraneItem',
    component: function () {
      return import('../views/CraneItem.vue')
    }
  },
  {
    path: '/elevator',
    name: 'Elevator',
    component: function () {
      return import('../views/Elevator.vue')
    }
  },
  {
    path: '/elevatorItem',
    name: 'ElevatorItem',
    component: function () {
      return import('../views/ElevatorItem.vue')
    }
  },
  {
    path: '/unload',
    name: 'Unload',
    component: function () {
      return import('../views/Unload.vue')
    }
  },
  {
    path: '/electrical',
    name: 'Electrical',
    component: function () {
      return import('../views/Electrical.vue')
    }
  },
  {
    path: '/green',
    name: 'Green',
    component: function () {
      return import('../views/Green.vue')
    }
  },
  {
    path: '/entrance/:id?',
    name: 'Entrance',
    component: function () {
      return import('../views/Entrance.vue')
    }
  },
  {
    path: '/demo',
    name: 'Demo',
    component: function () {
      return import('../views/Demo.vue')
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if ( (to.name !== 'Login' && to.name !== 'Sso' && to.name !== 'Entrance' && to.name !== 'Demo') && !localStorage.token) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
