import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import  EZUIKit from 'ezuikit-js'


import axios from "axios"
Vue.prototype.$axios = axios

import axiosAdmin from './axios/admin'
Vue.prototype.$axiosAdmin = axiosAdmin

import qs from 'qs'
Vue.prototype.$qs = qs

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import VueParticles from 'vue-particles'
Vue.use(VueParticles)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
