<template>
  <div class="home">
    <!-- <img class="logo" alt="Vue logo" src="../assets/41618903510_.pic_hd.jpg" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<style lang="less">

</style>

<script>
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
    // 进入全屏
    enterFullScreen: function () {
      let el = document.documentElement;
      let rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (rfs) {
        // typeof rfs != "undefined" && rfs
        rfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    // 退出全屏
    exitFullScreen: function () {
      let el = document;
      let cfs =
        el.cancelFullScreen ||
        el.mozCancelFullScreen ||
        el.msExitFullscreen ||
        el.webkitExitFullscreen ||
        el.exitFullscreen;
      if (cfs) {
        // typeof cfs != "undefined" && cfs
        cfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
  },
};
</script>
