<template>
  <div id="app">
    <div class="app-wrap" v-if="$route.name == 'Sso' || $route.name == 'Login' || $route.name == 'Entrance'">
      <router-view />
    </div>
    <div class="app-wrap" v-else>
      <div class="app-header">
        <!-- 标题栏 start -->
        <div class="title-wrap">
          <div class="title-left">
            <div class="safe-day" @click="onReload">
              <div class="safe">安全施工</div>
              <div class="day">{{ safeDay }}天</div>
            </div>
          </div>
          <div class="title-middle">
            <h1 @click="toggleFullscreen">智慧工地监管平台</h1>
          </div>
          <div class="title-right">
            <div class="logout" @click="onLogout">
              <!-- {{username}} -->
              <img class="icon" src="./assets/logout.png">
              退出
            </div>
            <div>{{ dataTimeStr }}</div>
          </div>
        </div>
        <!-- 标题栏 end -->

        <!-- 导航栏 start -->
        <div class="nav-wrap">
          <!-- 菜单项 -->
          <div class="nav-list">
            <div class="nav-list-left">
              <router-link to="/project">
                <div class="nav-item">项目管理</div>
              </router-link>
              <router-link to="/person">
                <div class="nav-item">劳务监工</div>
              </router-link>
              <router-link to="/video">
                <div class="nav-item">视频监控</div>
              </router-link>
              <router-link to="/ai">
                <div class="nav-item">智眸AI</div>
              </router-link>
              <router-link to="/crane">
                <div class="nav-item">塔吊监控</div>
              </router-link>
            </div>
            <div class="project" @click="toggleShowProjectList">
              <div>{{ currentProject.name }}</div>
              <div>▾</div>
            </div>
            <div class="nav-list-right">
              <router-link to="/elevator">
                <div class="nav-item">升降机监控</div>
              </router-link>
              <router-link to="/unload">
                <div class="nav-item">卸料平台监控</div>
              </router-link>
              <router-link to="/electrical">
                <div class="nav-item">配电监控</div>
              </router-link>
              <router-link to="/green">
                <div class="nav-item">绿色施工</div>
              </router-link>
            </div>
          </div>
          <!-- 项目选择 -->
          <transition name="project">
            <div class="project-list" v-show="showProjectList" @mouseleave="toggleShowProjectList(false)">
              <ul>
                <li
                  v-for="(item, index) in projectList"
                  :key="index"
                  @click.stop="onSelectProject(index)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </transition>
        </div>
        <!-- 导航栏 end -->

        <div class="main-wrap">
          <!-- 路由注入点 -->
          <transition name="main">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFullScreen: false,
      dataTimeStr: "2021.1.20 星期四 14:00:00",
      // safeDay: 365,
      showProjectList: false,
    };
  },
  computed: {
    currentRouter: function () {
      return this.$route.name
    },
    username: function () {
      return localStorage.getItem('username');
    },
    projectList: function () {
      return this.$store.state.projectList;
    },
    currentProject: function () {
      return this.$store.state.currentProject;
    },
    safeDay: function () {
      return this.$store.state.safeDay;
    }
    // safeDay: function () {
    //   var time = new Date();
    //   var format = function (val) {
    //     if (val < 10) {
    //       return "0" + val;
    //     } else {
    //       return val;
    //     }
    //   };
    //   var today = `${time.getFullYear()}-${format(time.getMonth()+1)}-${format(time.getDate())}`
    //   var startDate = Date.parse('2020-09-01')
    //   var endDate = Date.parse(today)
    //   if (startDate > endDate) {
    //     return 0
    //   }
    //   if (startDate == endDate) {
    //     return 1
    //   }
    //   var days = (endDate - startDate) / (1*24*60*60*1000)
    //   return days
    // }
  },
  watch: {
    currentRouter(newVal, oldVal) {
      console.log("watch currentRouter=>", newVal, oldVal);
      if (oldVal == 'Login' || oldVal == 'Sso') {
        this.onGetProjectList() // 获取项目列表
      }
    },
  },
  mounted() {
    this.onSetTimer(); // 设置当前时间
    this.onGetProjectList() // 获取项目列表
  },
  methods: {
    // 设置系统时钟
    onSetTimer: function () {
      setInterval(() => {
        var time = new Date();
        time.getYear(); //获取当前年份
        time.getFullYear(); //获取完整的年份(4位,1970-???)
        time.getMonth(); //获取当前月份(0-11,0代表1月)
        time.getDate(); //获取当前日(1-31)
        time.getDay(); //获取当前星期X(0-6,0代表星期天)
        time.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
        time.getHours(); //获取当前小时数(0-23)
        time.getMinutes(); //获取当前分钟数(0-59)
        time.getSeconds(); //获取当前秒数(0-59)
        time.getMilliseconds(); //获取当前毫秒数(0-999)
        time.toLocaleDateString(); //获取当前日期
        function getWeek(num) {
          if (num == 1) return "一";
          if (num == 2) return "二";
          if (num == 3) return "三";
          if (num == 4) return "四";
          if (num == 5) return "五";
          if (num == 6) return "六";
          if (num == 0) return "日";
        }
        var format = function (val) {
          if (val < 10) {
            return "0" + val;
          } else {
            return val;
          }
        };
        this.dataTimeStr = `${time.getFullYear()}.${format(
          time.getMonth()+1
        )}.${format(time.getDate())} 星期${getWeek(time.getDay())} ${format(
          time.getHours()
        )}:${format(time.getMinutes())}:${format(time.getSeconds())}`;
      }, 1000);
    },
    // 切换全屏状态
    toggleFullscreen() {
      if (this.isFullScreen) {
        this.exitFullScreen();
      } else {
        this.enterFullScreen();
      }
      this.isFullScreen = !this.isFullScreen;
    },
    // 进入全屏
    enterFullScreen: function () {
      let el = document.documentElement;
      let rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (rfs) {
        // typeof rfs != "undefined" && rfs
        rfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    // 退出全屏
    exitFullScreen: function () {
      let el = document;
      let cfs =
        el.cancelFullScreen ||
        el.mozCancelFullScreen ||
        el.msExitFullscreen ||
        el.webkitExitFullscreen ||
        el.exitFullscreen;
      if (cfs) {
        // typeof cfs != "undefined" && cfs
        cfs.call(el);
      } else if (typeof window.ActiveXObject !== "undefined") {
        // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    // 切换项目菜单的隐藏显示
    toggleShowProjectList: function () {
      this.showProjectList = !this.showProjectList;
    },
    // 获取项目列表
    onGetProjectList: function () {
      if (!localStorage.token) return
      var that = this;
      this.$axiosAdmin({
        method: "get",
        url: `api/admin/project/frontPage`,
      })
        .then((res) => {
          if (res.code === 0) {
            this.$store.commit("init", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换项目
    onSelectProject: function (index) {
      this.$store.commit("editCurrentProject", index);
      // this.showProjectList = false;
    },
    // 退出登录
    onLogout: function () {
      localStorage.clear()
      this.$router.push({name: 'Login'})
    },
    // 刷新页面
    onReload: function () {
      location.reload()
    }
  },
};
</script>

<style lang="less">
@Width: 192rem;
* {
  margin: 0;
  padding: 0;
}
html,
body {
  background-color: #0f1042;
  color: #fff;
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
a {
  color: #fff;
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/main-bg.png");
  filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  font-size: 12 / @Width;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 1920 / @Width;
  height: 1080 / @Width;
  .app-wrap {
    width: 1920 / @Width;
    height: 1080 / @Width;
    .app-header {
      // background-color: rgba(255,255,255,.4);
      margin: auto;
      margin-top: 20rem / @Width;
      width: 1840rem / @Width;
      height: 170rem / @Width;

      .title-wrap {
        // background-color: rgba(255,100,255,.4);
        display: flex;
        align-items: center;
        height: 100 / @Width;

        .title-left {
          width: 350 / @Width;
          height: 100 / @Width;
          .safe-day {
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 700;
            .safe {
              font-size: 32 / @Width;
            }
            .day {
              margin-left: 10 / @Width;
              font-size: 32 / @Width;
              color: #00e4ff;
            }
          }
        }
        .title-middle {
          width: 1140 / @Width;
          height: 63.198 / @Width;
          background-image: url("./assets/title-bg.png");
          filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
          -moz-background-size: 100% 100%;
          background-size: 100% 100%;

          h1 {
            margin: 0;
            padding: 0;
            width: 1140 / @Width;
            height: 63.198 / @Width;
            font-size: 30 / @Width;
            font-weight: 700;
            text-align: center;
            line-height: 63.198 / @Width;
            margin-top: -3px;
          }
        }
        .title-right {
          width: 350 / @Width;
          height: 100 / @Width;
          text-align: right;
          padding-top: 20 / @Width;
          font-weight: 700;
          box-sizing: border-box;
          color: #00e4ff;
          font-size: 18 / @Width;
          .logout {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            .icon {
              margin: 0 10 / @Width;
            }
          }
        }
      }
      .nav-wrap {
        // background-color: rgba(255, 0, 0, 0.4);
        position: relative;
        .nav-list {
          height: 56 / @Width;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .nav-list-left {
            width: 660 / @Width; // 662
            height: 56 / @Width;
            background-image: url("./assets/nav-left-bg.png");
            filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
            -moz-background-size: 100% 100%;
            background-size: 100% 100%;
            font-size: 22 / @Width;
            line-height: 56 / @Width;
            display: flex;
            justify-content: space-between;
            padding: 0 30 / @Width;
            box-sizing: border-box;
          }
          .nav-list-right {
            width: 660 / @Width;
            height: 56 / @Width;
            background-image: url("./assets/nav-right-bg.png");
            filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
            -moz-background-size: 100% 100%;
            background-size: 100% 100%;
            font-size: 22 / @Width;
            line-height: 56 / @Width;
            display: flex;
            justify-content: space-between;
            padding: 0 30 / @Width;
            box-sizing: border-box;
          }
          .nav-item {
            color: #00e4ff;
            margin: 0 15 / @Width;
          }
          .project {
            width: 460 / @Width;
            height: 65 / @Width;
            font-size: 24 / @Width;
            color: #00e4ff;
            text-align: center;
            line-height: 65 / @Width;
            background-image: url("./assets/project-bg.png");
            filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";
            -moz-background-size: 100% 100%;
            background-size: 100% 100%;

            display: flex;
            justify-content: center;

            div {
              margin: 0 10px;
            }
          }
          .router-link-exact-active {
            .nav-item {
              color: #fff;
            }
          }
        }
        .project-list {
          position: absolute;
          width: 440 / @Width;
          height: 200 / @Width;
          left: 700 / @Width;
          top: 60 / @Width;
          border: 1 / @Width solid #4a75ff;
          border-radius: 10 / @Width;
          box-sizing: border-box;
          // background-color: rgba(0, 0, 0, 0.8);
          // background-color: rgb(15, 16, 66);
          background-color: rgba(15, 16, 66, 0.9);
          box-shadow: 0 0 10 / @Width #fff;
          overflow: scroll;
          z-index: 999;

          ul {
            list-style-type: none;
            text-align: center;
            font-size: 18 / @Width;
            color: #00e4ff;
            margin: 10 / @Width;
            li {
              padding: 10 / @Width;
            }
          }
        }
      }
      .main-wrap {
        // background-color: rgba(255, 255, 0, 0.4);
        height: 840 / @Width;
        margin-top: 20 / @Width;
      }
    }
  }
}

// 全局动画效果
// 项目列表
.project-enter-active {
  animation: project-in 0.3s;
}
.project-leave-active {
  animation: project-in 0.3s reverse;
}
@keyframes project-in {
  0% {
    opacity: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
// 主题内容切换
.main-enter-active {
  animation: main-in 0.5s;
}
.main-leave-active {
  animation: main-in 0 reverse;
}
@keyframes main-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}



.activeImg {
  animation: active-img 3s linear infinite;
}

@keyframes active-img {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
