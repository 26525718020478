import axios from "axios"

// 全局配置
const axiosAdmin = axios.create({
    headers: {'Content-Type': 'application/json'},
})

// 请求拦截
axiosAdmin.interceptors.request.use(
    (config) => {
        if (localStorage.token) {
            config.headers.Authorization = 'Bearer ' + localStorage.token;
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
)

// 响应拦截
axiosAdmin.interceptors.response.use(
    (res) => {
        if (res.status === 200) {
            if (res.data.code != 0) {
                console.log('响应拦截=>data=[]')
                res.data.data = []
                return res
            }
            return res.data;
        } else {
            return res;
        }
    },
    (err) => {
        if (err.response.status === 401) {
            localStorage.clear()
            location.reload()
        }
        return Promise.reject(err); // 详细错误信息 err.response
    }
)

// 导出也要导正确
export default axiosAdmin